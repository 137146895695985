.testa::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  border-color: transparent;
  border-style: solid;
}
.testa::before {
  border-width: 0em;
  border-right-color: transparent;
  border-top-color: transparent;
}
.testa::after {
  border-width: 0em;
  border-bottom-right-radius: 0px;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
